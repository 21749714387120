import { ref } from '@vue/composition-api'

import { apiRetail } from '@/api'

export default function useRetail() {
  const saleReports = ref(null)
  const bookingReports = ref(null)
  const loading = ref(false)

  const overViewReports = payload => new Promise((resolve, reject) => {
    loading.value = true
    apiRetail
      .getAllReport(payload)
      .then(e => {
        if (e.status === 200) {
          saleReports.value = e.data.resSaleReport
          bookingReports.value = e.data.resBooking
          resolve({
            saleReports: saleReports.value,
            bookingReports: bookingReports.value,
          })
        }
      })
      .catch(e => {
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const retailReportCustomer = payload => new Promise((resolve, reject) => {
    loading.value = true
    apiRetail.getAllBookingRetail(payload)
      .then(e => {
        if (e.status === 200) {
          resolve(e.data.items)
        }
      })
      .catch(e => {
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  return {
    overViewReports,
    retailReportCustomer,
    loading,
  }
}
