<template>
  <section id="dashboard-ecommerce">
    <IAmOverlay :loading="loading.value">
      <!-- Filter -->
      <b-card>
        <b-card-body class="p-0">
          <h4 class="mb-50 fw-700">
            {{ $t('filters') }}
          </h4>
          <b-row>
            <!-- START DATE -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label-class="h6 my-25"
                :label="$t('topup.startDate')"
                label-for="startDate"
                class="mb-0"
              >
                <flat-pickr
                  id="startDate"
                  v-model="startDate"
                  class="form-control"
                  :placeholder="$t('topup.placeholderSelectDate')"
                  :config="{
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    altInput: true,
                    locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                    disableMobile: true,
                    maxDate: endDate,
                  }"
                />
              </b-form-group>
            </b-col>

            <!-- END DATE -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label-class="h6 my-25"
                :label="$t('topup.endDate')"
                label-for="startDate"
                class="mb-0"
              >
                <flat-pickr
                  id="startDate"
                  v-model="endDate"
                  class="form-control"
                  :placeholder="$t('topup.placeholderSelectDate')"
                  :config="{
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    altInput: true,
                    locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                    disableMobile: true,
                    minDate: startDate,
                    maxDate: new Date(),
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label-class="h5 py-0"
                :label="$t('agency.agencyCode')"
              >
                <SelectChildAgency
                  id="agency-code"
                  :agency-select.sync="agencySelect"
                  class="w-100"
                  style="font-size: 1rem;"
                  :placeholder="$t('saleReport.phAgencyCode')"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="pt-lg-1 mt-75"
            >
              <b-button
                variant="warning"
                @click="search(startDate, endDate)"
              >
                <span class="text-nowrap">
                  {{ $t('search') }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Tổng quát -->
      <b-card
        no-body
        class="p-0"
      >
        <b-card-body class="statistics-body">
          <h4 class="mb-50 fw-700">
            {{ $t('retail.general') }}
          </h4>
          <b-row>
            <b-col
              v-for="(statisticsItem, statisticsIndex) in statisticsItems"
              :key="statisticsIndex"
              md="4"
              sm="6"
              :class="statisticsItem.customClass"
            >
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    :variant="statisticsItem.color"
                  >
                    <feather-icon
                      size="24"
                      :icon="statisticsItem.icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto d-flex-between flex-column align-items-start">
                  <h4 class="font-weight-bolder font-medium-5 mb-0">
                    {{ statisticsItem.title }}
                  </h4>
                  <b-card-text class="font-medium-1 mb-0 font-weight-bold">
                    {{ $t(`retail.${statisticsItem.subtitle}`) }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Bảng thống kê -->
      <b-row class="match-height">
        <b-col xl="3">
          <DetailTableItems
            title-table="book"
            :table-data="bookingReportsDetail"
          />
        </b-col>
        <b-col xl="9">
          <DetailTableItems
            title-table="payment"
            :table-data="saleReportsDetail"
          />
        </b-col>
      </b-row>
    </IAmOverlay>
  </section>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton,
  BCard, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'

import { formatCurrency } from '@core/utils/filter'

import useRetailHandle from '@retail/useRetailHandle'

const { overViewReports, loading } = useRetailHandle()

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCard,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    DetailTableItems: () => import('./DetailTableItems.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    SelectChildAgency: () => import('@/views/apps/retail/components/SelectChildAgency.vue'),
    flatPickr,
  },

  data() {
    return {
      Vietnamese,
      overViewReports,
      loading,
      currentDate: new Date(),
      endDate: null,
      startDate: null,
      agencySelect: null,
      revenue: 0,
      bookingCount: 0,
      totalReservation: 0,
      totalCustomer: 0,
      detailRevenue: null,
      bookingReportsDetail: [],
      saleReportsDetail: [],
      rangeDate: [],

      totalProfit: 0,
      totalReceivables: 0,
      totalTradeCreditors: 0,
      totalPaymentFinal: 0,

      statisticsItems: [
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'totalCustomer',
          customClass: 'mb-1',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'totalBookings',
          customClass: 'mb-1',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'totalPaymentFinal',
          customClass: 'mb-1',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'totalTradeCreditors',
          customClass: 'mb-1 mb-md-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'totalReceivables',
          customClass: 'mb-1 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'totalProfit',
          customClass: 'mb-0',
        },
      ],
    }
  },
  created() {
    this.updateDateRange(this.currentDate)
  },

  methods: {
    updateDateRange(timeStart = null, timeEnd = null) {
      const endDate = timeEnd ? new Date(timeEnd) : new Date()
      const formattedEndDate = endDate.toISOString().split('T')[0]
      const startDate = timeStart ? new Date(timeStart) : new Date(endDate)

      startDate.setMonth(startDate.getMonth() - 1)
      const formattedStartDate = startDate.toISOString().split('T')[0]
      this.startDate = formattedStartDate
      this.endDate = formattedEndDate
      this.overViewReports({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }).then(({ saleReports, bookingReports }) => {
        this.revenue = saleReports.allTotalPayment
        this.bookingCount = bookingReports.total
        this.bookingReportsDetail = bookingReports.items
        this.bookingReportsDetail = bookingReports.items
        this.saleReportsDetail = saleReports.items
        this.statisticsItems[5].title = formatCurrency(saleReports.totalProfit || 0)
        this.statisticsItems[2].title = formatCurrency(saleReports.totalPaymentFinal || 0)
        this.statisticsItems[3].title = formatCurrency(saleReports.totalTradeCreditors || 0)
        this.statisticsItems[4].title = formatCurrency(saleReports.totalReceivables || 0)
        this.statisticsItems[0].title = saleReports.totalCustomer || 0
        this.statisticsItems[1].title = bookingReports.total || 0
        this.totalPaymentFinal = saleReports.totalPaymentFinal
      })
    },

    search(timeStart = null, timeEnd = null) {
      const endDate = timeEnd ? new Date(timeEnd) : new Date()
      const formattedEndDate = endDate.toISOString().split('T')[0]
      const startDate = timeStart ? new Date(timeStart) : new Date(endDate)

      const formattedStartDate = startDate.toISOString().split('T')[0]

      this.overViewReports({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        agencyCode: this.agencySelect.agencyCode ?? undefined,
      })
        .then(({ saleReports, bookingReports }) => {
          this.revenue = saleReports.allTotalPayment
          this.bookingCount = bookingReports.total
          this.bookingReportsDetail = bookingReports.items
          this.bookingReportsDetail = bookingReports.items
          this.saleReportsDetail = saleReports.items
          this.statisticsItems[5].title = formatCurrency(saleReports.totalProfit || 0)
          this.statisticsItems[2].title = formatCurrency(saleReports.totalPaymentFinal || 0)
          this.statisticsItems[3].title = formatCurrency(saleReports.totalTradeCreditors || 0)
          this.statisticsItems[4].title = formatCurrency(saleReports.totalReceivables || 0)
          this.statisticsItems[0].title = saleReports.totalCustomer || 0
          this.statisticsItems[1].title = bookingReports.total || 0
          this.totalPaymentFinal = saleReports.totalPaymentFinal
        })
    },
  },
}
</script>

<style lang="scss">
</style>
