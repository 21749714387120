var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "dashboard-ecommerce"
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading.value
    }
  }, [_c('b-card', [_c('b-card-body', {
    staticClass: "p-0"
  }, [_c('h4', {
    staticClass: "mb-50 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h6 my-25",
      "label": _vm.$t('topup.startDate'),
      "label-for": "startDate"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "startDate",
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true,
        maxDate: _vm.endDate
      }
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h6 my-25",
      "label": _vm.$t('topup.endDate'),
      "label-for": "startDate"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "startDate",
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true,
        minDate: _vm.startDate,
        maxDate: new Date()
      }
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('agency.agencyCode')
    }
  }, [_c('SelectChildAgency', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "agency-code",
      "agency-select": _vm.agencySelect,
      "placeholder": _vm.$t('saleReport.phAgencyCode')
    },
    on: {
      "update:agencySelect": function updateAgencySelect($event) {
        _vm.agencySelect = $event;
      },
      "update:agency-select": function updateAgencySelect($event) {
        _vm.agencySelect = $event;
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "pt-lg-1 mt-75",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.search(_vm.startDate, _vm.endDate);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('search')) + " ")])])], 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('h4', {
    staticClass: "mb-50 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('retail.general')) + " ")]), _c('b-row', _vm._l(_vm.statisticsItems, function (statisticsItem, statisticsIndex) {
    return _c('b-col', {
      key: statisticsIndex,
      class: statisticsItem.customClass,
      attrs: {
        "md": "4",
        "sm": "6"
      }
    }, [_c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', {
      staticClass: "mr-2"
    }, [_c('b-avatar', {
      attrs: {
        "size": "48",
        "variant": statisticsItem.color
      }
    }, [_c('feather-icon', {
      attrs: {
        "size": "24",
        "icon": statisticsItem.icon
      }
    })], 1)], 1), _c('b-media-body', {
      staticClass: "my-auto d-flex-between flex-column align-items-start"
    }, [_c('h4', {
      staticClass: "font-weight-bolder font-medium-5 mb-0"
    }, [_vm._v(" " + _vm._s(statisticsItem.title) + " ")]), _c('b-card-text', {
      staticClass: "font-medium-1 mb-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t("retail.".concat(statisticsItem.subtitle))) + " ")])], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "xl": "3"
    }
  }, [_c('DetailTableItems', {
    attrs: {
      "title-table": "book",
      "table-data": _vm.bookingReportsDetail
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "9"
    }
  }, [_c('DetailTableItems', {
    attrs: {
      "title-table": "payment",
      "table-data": _vm.saleReportsDetail
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }